import React, { useState } from "react";
import { motion } from "framer-motion";
import { greenCheckMark, darkgreenCheckMark } from "../../Assets";
import { Lovpakker } from '../../infoArray';
import styles, { customColor } from "../../style";
import {useNavigate} from "react-router-dom";

const Lovpakken = () => {
    const [fullPrice, setFullPrice] = useState(false);

    const truncateText = (text: string, maxLength: number) => {
        return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    };

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/Holdstart`;
        navigate(path);

        // Scroll to element with id="AdditionalExpenses"
        setTimeout(() => {
            const element = document.getElementById("otherDeals");
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "end" });
            }
        }, 300); // Adjust delay if necessary, to ensure navigation completes before scrolling
    };


    return (
        <div className="py-10 md:px-14 p-4 max-w-screen-2xl mx-auto" id="pricing">
            <div className="text-center">
                <h2 className="text-white dark:text-black md:text-4xl text-2xl font-extrabold mb-2">
                    Overvejer du at starte på dit kørekort? Så se med her:
                </h2>
                {/*<p className={`${styles.paragraphNoColor} ${styles.flexCenter} text-white dark:text-gray-500 font-semibold text-center lg:mx-80 mb-5`}>*/}
                {/*    Vælg pakken der passer dine behov.*/}
                {/*</p>*/}
                {/*<div className="mt">*/}
                {/*    <label htmlFor="toggle" className="inline-flex items-center cursor-pointer">*/}
                {/*        <span className="text-white dark:text-black mr-4 md:mr-8 text-xl md:text-2xl font-semibold">Månedlig Rate</span>*/}
                {/*        <div className={`w-12 md:w-14 h-6 transition duration-200 ${fullPrice ? 'bg-green-300' : 'bg-gray-300'} ease-in-out rounded-full`}>*/}
                {/*            <div className={`w-6 h-6 transition duration-200 ease-in-out rounded-full ${fullPrice ? 'bg-green-700 ml-8' : 'bg-gray-500'}`}></div>*/}
                {/*        </div>*/}
                {/*        <span className="text-white dark:text-black ml-4 md:ml-8 text-xl md:text-2xl font-semibold">Fuld pris</span>*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        type="checkbox"*/}
                {/*        id="toggle"*/}
                {/*        className="hidden"*/}
                {/*        checked={fullPrice}*/}
                {/*        onChange={() => setFullPrice(!fullPrice)}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>

            <motion.div
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="grid sm:grid-cols-1 lg:grid-cols-1 grid-cols-1 gap-10 max-w-[500px] mt-10 mx-auto">
                {Lovpakker.slice(0, 2).map((Pakke, index) => (
                    <div key={index} className={`dark:border py-10 md:px-6 px-4 rounded-lg shadow-3xl
                        transition-transform transform hover:scale-105 hover:shadow-2xl ${customColor.gradientDarkBlueNoDarkMode} flex flex-col`}>
                        <div className="flex flex-col items-center flex-grow">
                            <h3 className="text-white dark:text-black text-2xl font-bold text-center">{Pakke.name}</h3>
                            <p className="text-white dark:text-black text-tertiary text-center my-4">
                                {truncateText(Pakke.description, 189)}
                            </p>
                            <p className="text-white dark:text-black mt-5 text-center text-secondary text-4xl font-bold">
                                {fullPrice ? `${Pakke.yearlyPrice}` : `${Pakke.monthlyPrice}`}
                            </p>
                        </div>
                        <ul className="text-white dark:text-black mt-4 space-y-2 px-4 flex-grow">
                            <li className="flex flex-row gap-1 items-center">
                                <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                {Pakke.info1}
                            </li>
                            <li className="flex flex-row gap-1 items-center">
                                <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                {Pakke.info2}
                            </li>
                            <li className="flex flex-row gap-1 items-center">
                                <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                {Pakke.info3}
                            </li>
                            {/* Hide info4 and info5 for the third deal */}
                            {index !== 2 && (
                                <>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info4}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info5}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info6}
                                    </li>
                                </>
                            )}
                        </ul>
                        <div>
                            <p className={`${styles.paragraphNoColor} ${styles.flexCenter} 
                                       text-white dark:text-black font-semibold text-sm leading-5 text-center mt-6`}>
                                Alt betales løbende (teoriundersning og manøvrebane betales inden manøvrebane).
                            </p>
                        </div>
                        <motion.div whileHover={{ scale: 1.05 }}>
                            <p onClick={routeChange}
                               className={`${styles.paragraphNoColor} ${styles.flexCenter} 
                                       text-white dark:text-gray-500 underline text-tertiary text-sm leading-6 text-center mt-6`}>
                                {Pakke.description2}
                            </p>
                        </motion.div>

                        {/* button */}
                        <div className="w-full flex items-center justify-center mt-auto">
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <button className="text-white dark:text-black mt-6 px-10 text-secondary border border-secondary
                                    font-semibold py-2 rounded-lg hover:shadow-md light:hover:border-gray-500"
                                        onClick={routeChange} >
                                    Tilmeld dig her!
                                </button>
                            </motion.div>
                        </div>
                    </div>
                ))}
            </motion.div>

            <motion.div whileHover={{ scale: 1.05 }}>
                <p onClick={routeChange}
                   className={`${styles.paragraphNoColor} ${styles.flexCenter} text-white dark:text-gray-500 font-semibold text-center lg:mx-80 mt-5 underline`}>
                    Se alle de forskellige pakker jeg tilbyder her
                </p>
            </motion.div>


        </div>
    );
};

export default Lovpakken;