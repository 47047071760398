import React, { useState } from 'react';
import styles, { customColor } from '../../style';
import emailjs from '@emailjs/browser';
import { emailSchema } from "./EmailValidation";
import { motion } from 'framer-motion';
import {ContactInfo} from '../../infoArray';
import {darkgreenCheckMark, greenCheckMark} from "../../Assets";

const contactDetails = ContactInfo.map(info => {
    return {
        label: info.id === "TelefonNr" ? "Telefon nummer" : "Email",
        value: info.Number || info.email
    };
});

const ContactFormular = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);

        const isValidMessage = await validateMessage();
        if (!isValidMessage) {
            console.log('Invalid message');
            return;
        }

        const serviceId = 'service_r3wizdg';
        const templateId = 'template_t5jf4r9';
        const publicKey = '4emBEfuzcXPMu5crm';

        if (!serviceId || !templateId || !publicKey) {
            console.error("EmailJS configuration is missing");
            return;
        }

        const templateParams = {
            from_name: name,
            email,
            to_name: 'Stephans Køreskole',
            message,
        };

        emailjs
            .send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('Email sent successfully!', response);
                setName('');
                setEmail('');
                setMessage('');
                setIsMessageSent(true);
            })
            .catch((error) => {
                console.log('Error sending email', error);
            });
    };

    const validateMessage = async () => {
        const emailData = {
            name,
            email,
            message
        };

        try {
            await emailSchema.validate(emailData);
            console.log('Email is valid');
            return true;
        } catch (error) {
            console.error('Email validation error:', error);
            return false;
        }
    };

    return (
        <motion.section
            id="Kontakt"
            className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} ${customColor.gradientDarkBlueNoDarkMode}
            md:flex-row flex-col shadow-lg rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl
             dark:border-none border-[1px] border-gray-800`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <motion.div className="flex-1 flex flex-col"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
            >
                <h2 className={`${styles.heading2} text-center md:text-start`}>
                    Kontakt
                </h2>
                <p className={`${styles.paragraph} max-w-[470px] text-center md:text-start`}>
                    Du er altid velkommen til at kontakte mig, hvis du har nogle spørgsmål.
                    Du kan enten kontakt mig ved at udfylde denne formular eller bruge telefon nummeret forneden:
                </p>
                <div className="text-white dark:text-black text-center md:text-start mt-5 sm-0 md:mb-24">
                    {contactDetails.map(detail => (
                        <div key={detail.label} className="flex justify-center md:justify-start mt-2">
                            <span className="font-bold mr-2">{detail.label}:</span>
                            <span>{detail.value}</span>
                        </div>
                    ))}
                </div>
            </motion.div>

            {isMessageSent ? (
                <div className="text-center text-white dark:text-black p-8">
                    <>
                        <p className="text-center mt-4">
                            <img src={darkgreenCheckMark} alt="icon" className="w-[25%] h-[25%] object-contain dark:hidden" />
                            <img src={greenCheckMark} alt="icon" className="w-[25%] h-[25%] object-contain hidden dark:inline" />
                        </p>
                        <p className="text-black font-semibold text-center mt-4">
                            Din besked er nu blevet sendt,
                        </p>
                        <p className="text-black font-semibold text-center">
                            jeg vil vende tilbage til dig hurtigst muligt😊
                        </p>
                    </>
                </div>
            ) : (
                <motion.form
                    onSubmit={handleSubmit}
                    className="sm:w-96 md:w-1/2 lg:w-2/4 xl:w-2/4 p-8 rounded mx-auto mt-8 sm:mt-0"
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium text-white dark:text-black">
                            Navn:
                        </label>
                        <input
                            type="text"
                            placeholder="Navn..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && name === '' && 'border-red-500'}`}
                        />
                        {formSubmitted && name === '' && (
                            <p className="text-red-500 text-sm mt-1">Navn er påkrævet</p>
                        )}
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-white dark:text-black">
                            E-mail:
                        </label>
                        <input
                            type="email"
                            placeholder="e-mail..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && email === '' && 'border-red-500'}`}
                        />
                        {formSubmitted && email === '' && (
                            <p className="text-red-500 text-sm mt-1">Email er påkrævet</p>
                        )}
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 1 }}
                    >
                        <label
                            htmlFor="message"
                            className="block text-sm font-medium text-white dark:text-black">
                            Besked:
                        </label>
                        <textarea
                            placeholder="Skriv din besked her..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && message === '' && 'border-red-500'}`}></textarea>
                        {formSubmitted && message === '' && (
                            <p className="text-red-500 text-sm mt-1">Besked er påkrævet</p>
                        )}
                    </motion.div>

                    <div>
                        <button
                            type="submit"
                            className={`block mx-auto bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-md`}
                        >
                            Send
                        </button>
                    </div>

                </motion.form>
            )}
        </motion.section>
    );
};

export default ContactFormular;
