import React from "react";
import { motion } from "framer-motion";
import { darkgreenCheckMark, greenCheckMark } from "../../Assets";
import {andreUdgifterData, otherDeals} from '../../infoArray';
import styles, { customColor } from "../../style";

const OtherDeals = () => {
    const scrollToContactFormular = () => {
        // Find the element with the id "SignUp"
        const kontaktElement = document.getElementById("SignUp");

        // Check if the element is found
        if (kontaktElement) {
            kontaktElement.scrollIntoView({
                behavior: "smooth",
                block: "start", // You can change this to "center" or "end" based on your preference
            });
        }
    };

    return (
        <div className="py-5 md:px-10 p-4 max-w-screen-2xl mx-auto" id="otherDeals">
            <div className="text-center">
                <h2 className={`${styles.flexCenter} text-center font-poppins font-bold text-[42px] lg:text-[52px]
             leading-[55px] lg:leading-[75px] ${customColor.titleColor}`}>
                    Jeg tilbyder også</h2>
                {/*<h3> Bare et eksempel for det visuelles skyld, men kan nemt fjernes.</h3>*/}
            </div>

            <motion.div
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.2 }}
                className="grid sm:grid-cols-2 grid-cols-1 gap-10 max-w-fit mt-10 mx-auto"
            >

                {otherDeals.map((Pakke, index) => (
                    <div key={index} className={`dark:border py-10 md:px-6 px-4 rounded-lg shadow-3xl h-fit
                             transition-transform transform hover:scale-105 hover:shadow-2xl ${customColor.gradientDarkBlueNoDarkMode} flex flex-col justify-between`}>
                        <div>
                            <h3 className="text-white dark:text-black text-2xl font-bold text-center">{Pakke.name}</h3>
                            {/*<p className="text-white dark:text-black text-tertiary text-center mb-3">{Pakke.description}</p>*/}
                            {/*<p className="text-white dark:text-black mt-5 text-center text-secondary text-4xl font-bold">*/}
                            {/*    {Pakke.monthlyPrice} kr*/}
                            {/*</p>*/}
                            {/*<ul className="text-white dark:text-black mt-4 space-y-2 px-4">*/}
                            {/*    <li className="flex flex-row gap-1 items-center">*/}
                            {/*        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />*/}
                            {/*        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />*/}
                            {/*        {Pakke.info1}*/}
                            {/*    </li>*/}
                            {/*    <li className="flex flex-row gap-1 items-center">*/}
                            {/*        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />*/}
                            {/*        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />*/}
                            {/*        {Pakke.info2}*/}
                            {/*    </li>*/}
                            {/*    <li className="flex flex-row gap-1 items-center">*/}
                            {/*        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />*/}
                            {/*        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />*/}
                            {/*        {Pakke.info3}*/}
                            {/*    </li>*/}
                            {/*    <li className="flex flex-row gap-1 items-center">*/}
                            {/*        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />*/}
                            {/*        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />*/}
                            {/*        {Pakke.info4}*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            <p className="text-white dark:text-black text-tertiary text-center mt-6">
                                {Pakke.description2}
                            </p>
                        </div>
                        <motion.div whileHover={{ scale: 1.05 }}>
                            <div className="w-full mx-auto flex items-center justify-center">
                                <button className="text-white dark:text-black mt-6 px-10 text-secondary border border-secondary
                                    font-semibold py-2 rounded-lg hover:shadow-md light:hover:border-gray-500"
                                        onClick={scrollToContactFormular} >
                                    Tilmeld dig her!
                                </button>
                            </div>
                        </motion.div>
                    </div>
                ))}

                <div id="yderligereUdgifter" className={`dark:border py-10 md:px-6 px-4 rounded-lg shadow-3xl
     transition-transform transform hover:scale-105 hover:shadow-2xl ${customColor.gradientDarkBlueNoDarkMode}`}>
                    <h3 className="dark:text-black text-white text-2xl font-bold text-center mb-6">
                        Yderligere udgifter
                    </h3>
                    <ul className="dark:text-black text-white space-y-4">
                        {andreUdgifterData.map((expense, index) => (
                            <li key={index} className="flex justify-between items-center">
                                <span className="flex-1">{expense.name}</span>
                                <span className="font-semibold text-right">{expense.cost}</span>
                            </li>
                        ))}
                    </ul>
                    <p className="text-white dark:text-black text-tertiary text-center mt-6">
                        Betalingen af teori + manøvrebane (5.400 kr.), betales inden
                        manøvrebane. Køretimerne betaler man løbende for efter hver køretime (f.eks. via
                        mobilepay).
                    </p>
                    <p className="text-white dark:text-black text-tertiary text-center mt-6">
                        Alt skal være afregnet inden man går til køreprøve.
                        Før teoriprøven kan bookes skal man aflevere lægeerklæring, pasfoto og
                        førstehjælp.
                    </p>
                    <p className="text-white dark:text-black text-tertiary text-center mt-6">
                        Afbud og ændring af køretetime skal ske senest dagen før inden kl 12.00. Der skal
                        betales fuld pris for ikke overholdt aftale.
                    </p>
                </div>

            </motion.div>
        </div>
    );
};

export default OtherDeals;