import React from "react";
import Accordion from "./Accordion";
import { FAQData } from "../../infoArray";
import {customColor} from "../../style";

const FAQAccordion: React.FC = () => {
    return (
        <div className={`p-4 rounded-xl dark:bg-gray-100 dark:text-black text-white ${customColor.gradientDarkBlueNoDarkMode}`}>
            {FAQData.map((faq, index) => (
                <Accordion
                    key={faq.id}
                    title={faq.question}
                    answer={faq.answer}
                    isLast={index === FAQData.length - 1}
                />
            ))}
        </div>
    );
};

export default FAQAccordion;
