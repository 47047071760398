import React from 'react';
import styles from '../style';
import {ContactFormular, Footer, GoogleReviewsButton, Recommendations} from "../Components/ComponentsIndex";
import BackToTopButton from "../Components/ExtraButtons/BackToTopButton";
import GoogleMaps from "../Components/GoogleMaps/GoogleMaps";

const ContactPage = () => {

    return (
        <div className = "bg-black dark:bg-gray-50 w-full overflow-hidden" >

            <div className = {`bg-black dark:bg-gray-50 ${styles.flexStart} mt-[100px]`} >
                <div className = {`bg-black dark:bg-gray-50 ${styles.boxWidth}`} >
                    <ContactFormular />
                    <GoogleMaps />
                    {/*<Recommendations/>*/}
                </div >
            </div >

            <div className = {`bg-zinc-900 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-zinc-900 ${styles.boxWidth}`} >
                    <Footer />
                </div >
            </div >
            <BackToTopButton />
            <GoogleReviewsButton />
        </div >
    );
}

export default ContactPage;
