import React, { useState } from 'react';
import styles, { customColor } from '../../style';
import emailjs from '@emailjs/browser';
import { emailSchema } from "./EmailValidation";
import { motion } from 'framer-motion';
import {darkgreenCheckMark, greenCheckMark} from "../../Assets";

const SignUpFormular = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [licenseType, setLicenseType] = useState('Bil Kørekort');
    const [startDate, setStartDate] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);

        const isValidForm = await validateForm();
        if (!isValidForm) {
            console.log('Invalid form');
            return;
        }

        const serviceId = 'service_r3wizdg';
        const templateId = 'template_lu2wlmh';
        const publicKey = '4emBEfuzcXPMu5crm';

        if (!serviceId || !templateId || !publicKey) {
            console.error("EmailJS configuration is missing");
            return;
        }

        const templateParams = {
            from_name: name,
            email,
            phone,
            licenseType,
            startDate,
            to_name: 'Stephans Køreskole',
            message,
        };

        emailjs
            .send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log('Email sent successfully!', response);
                setName('');
                setEmail('');
                setPhone('');
                setLicenseType('Bil Kørekort');
                setStartDate('');
                setMessage('');
                setIsMessageSent(true);
            })
            .catch((error) => {
                console.log('Error sending email', error);
            });
    };

    const validateForm = async () => {
        const emailData = {
            name,
            email,
            phone,
            licenseType,
            startDate,
            message,
        };

        try {
            await emailSchema.validate(emailData, { context: { optionalMessage: true } });
            console.log('Form is valid');
            return true;
        } catch (error) {
            console.error('Form validation error:', error);
            return false;
        }
    };

    return (
        <motion.section
            id="SignUp"
            className={`${styles.flexCenter} ${styles.marginY} sm:px-16 px-6 ${customColor.gradientDarkBlueNoDarkMode}
              md:flex-row flex-col shadow-lg rounded-lg dark:border-none border-[1px] border-gray-800
               transition-transform transform hover:scale-105 hover:shadow-2xl dark:bg-white`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <motion.div className="flex-1 flex flex-col"
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
            >
                <h2 className="font-semibold text-[32px] lg:text-[42px] leading-[35px] lg:leading-[55px]
                 text-white dark:text-black text-center md:text-start mt-6 lg:mt-0">
                    Har du nogle spørgsmål? Så du mere end velkommen til kontakte mig
                </h2>
                <p className={`${styles.paragraph} md:max-w-[470px] mt-5 text-center md:text-start`}>
                    Udfyld venligst kontakt formularen, så sender jeg dig en besked, når jeg har sat dig ind på det ønsket hold.
                </p>
            </motion.div>

            {isMessageSent ? (
                <div className="text-center text-white dark:text-black p-8">
                    <>
                        <p className="text-center mt-4">
                            <img src={darkgreenCheckMark} alt="icon" className="w-[25%] h-[25%] object-contain dark:hidden" />
                            <img src={greenCheckMark} alt="icon" className="w-[25%] h-[25%] object-contain hidden dark:inline" />
                        </p>
                        <p className="text-black font-semibold text-center mt-4">
                            Din besked er nu blevet sendt,
                        </p>
                        <p className="text-black font-semibold text-center">
                            jeg vil vende tilbage til dig hurtigst muligt😊
                        </p>
                    </>
                </div>
            ) : (
                <motion.form
                    onSubmit={handleSubmit}
                    className="sm:w-96 md:w-1/2 lg:w-2/4 xl:w-2/4 p-8 rounded mx-auto mt-8 sm:mt-0"
                    initial={{ x: 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <label htmlFor="name" className="block text-sm font-medium text-white dark:text-black">
                            Navn:
                        </label>
                        <input
                            type="text"
                            placeholder="Navn..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && name === '' && 'border-red-500'}`}
                        />
                        {formSubmitted && name === '' && (
                            <p className="text-red-500 text-sm mt-1">Navn er påkrævet</p>
                        )}
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <label htmlFor="email" className="block text-sm font-medium text-white dark:text-black">
                            E-mail:
                        </label>
                        <input
                            type="email"
                            placeholder="e-mail..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && email === '' && 'border-red-500'}`}
                        />
                        {formSubmitted && email === '' && (
                            <p className="text-red-500 text-sm mt-1">Email er påkrævet</p>
                        )}
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 1 }}
                    >
                        <label htmlFor="phone" className="block text-sm font-medium text-white dark:text-black">
                            Telefon nr:
                        </label>
                        <input
                            type="text"
                            placeholder="Telefon..."
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && phone === '' && 'border-red-500'}`}
                        />
                        {formSubmitted && phone === '' && (
                            <p className="text-red-500 text-sm mt-1">Telefon nr. er påkrævet</p>
                        )}
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 1.2 }}
                    >
                        <label htmlFor="licenseType" className="block text-sm font-medium text-white dark:text-black">
                            Kørekort type:
                        </label>
                        <div className="flex flex-wrap mt-2">
                            <label className="mr-4 mb-2 text-white dark:text-black">
                                <input
                                    type="radio"
                                    name="licenseType"
                                    value="Bil Kørekort"
                                    checked={licenseType === 'Bil Kørekort'}
                                    onChange={(e) => setLicenseType(e.target.value)}
                                    className="mr-2"
                                />
                                Bil Kørekort
                            </label>
                            <label className="mr-4 mb-2 text-white dark:text-black">
                                <input
                                    type="radio"
                                    name="licenseType"
                                    value="Generhvervelse"
                                    checked={licenseType === 'Generhvervelse'}
                                    onChange={(e) => setLicenseType(e.target.value)}
                                    className="mr-2"
                                />
                                Generhvervelse
                            </label>
                        </div>
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 1.4 }}
                    >
                        <label htmlFor="startDate" className="block text-sm font-medium text-white dark:text-black">
                            Hold start dato:
                        </label>
                        <input
                            type="date"
                            placeholder="Hold start dato..."
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className={`mt-1 p-2 w-full border rounded-md ${formSubmitted && startDate === '' && 'border-red-500'}`}
                        />
                        {formSubmitted && startDate === '' && (
                            <p className="text-red-500 text-sm mt-1">Hold start dato er påkrævet</p>
                        )}
                    </motion.div>

                    <motion.div className="mb-4"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5, delay: 1.6 }}
                    >
                        <label htmlFor="message" className="block text-sm font-medium text-white dark:text-black">
                            Yderligere spørgsmål eller besked:
                        </label>
                        <textarea
                            placeholder="Skriv din besked her..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="mt-1 p-2 w-full border rounded-md"></textarea>
                    </motion.div>

                    <div>
                        <button
                            type="submit"
                            className="block mx-auto bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-md"
                        >
                            Send
                        </button>
                    </div>
                </motion.form>
            )}
        </motion.section>
    );
};

export default SignUpFormular;
