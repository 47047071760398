import React, {useEffect, useState} from "react";
import {closeDark, googleLogo, GoogleReviewsIcon} from "../../Assets";
import styles from "../../style";
import {motion} from "framer-motion";

const Star = ({filled}: { filled: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={filled ? "orange" : "gray"}
        className="w-10 h-10"
    >
        <path
            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        />
    </svg>
);

export const GoogleReviewsButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [sidebarOut, setSidebarOut] = useState(false);
    const [rating, setRating] = useState<number | null>(null);
    const [feedbackGiven, setFeedbackGiven] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleRatingSubmit = () => {
        if (rating !== null) {
            if (rating < 4) {
                setFeedbackGiven(true);
            } else {
                window.open('https://g.page/r/CQbzGHG3rhbeEAE/review', '_blank');
                setSidebarOut(false);
                resetFeedback();
            }
        }
    };

    const handleFeedbackSubmit = () => {
        // Handle feedback submission logic here
        resetFeedback();
    };

    const resetFeedback = () => {
        setRating(null);
        setFeedbackGiven(false);
        setSidebarOut(false);
    };

    return (
        <div className="fixed bottom-4 right-4 z-50">
            {isVisible && !sidebarOut && (
                <div
                    className={`rounded-full bg-white backdrop-filter backdrop-blur-lg bg-opacity-5 
                    border border-gray-700 dark:border-gray-200
                    h-[40px] w-[40px] text-white text-2xl ${styles.flexCenter}`}
                    onClick={() => setSidebarOut(true)}
                >
                    <img src={GoogleReviewsIcon} alt="GoogleReviewsIcon"
                         className="w-[100%] h-[100%] object-contain"/>
                </div>
            )}

            {sidebarOut && (
                <div className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center z-50">
                    <div className={`flex items-center justify-center w-full h-full`}>
                        {!feedbackGiven ? (
                            <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                                <button className={`rounded-full h-[30px] w-[30px]`}
                                        onClick={() => setSidebarOut(false)}>
                                    <img src={closeDark} alt="closeDark"
                                         className="w-[100%] h-[100%] object-contain"/>
                                </button>

                                <div className="flex justify-center mt-2">
                                    <img src={googleLogo} alt="GoogleReviewsIcon2"
                                         className="w-[50%] h-[50%] object-contain"/>
                                </div>

                                <h2 className="text-2xl mb-4">Fortæl os gerne, hvad du synes om os</h2>
                                <div className="flex justify-center space-x-2 mb-4">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <button
                                            key={star}
                                            onClick={() => setRating(star)}
                                            className="focus:outline-none"
                                        >
                                            <Star filled={rating !== null && rating >= star}/>
                                        </button>
                                    ))}
                                </div>
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={handleRatingSubmit}
                                    disabled={rating === null}
                                >
                                    Submit
                                </button>
                            </div>
                        ) : (
                            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                                <button className={`rounded-full h-[30px] w-[30px]`}
                                        onClick={() => setSidebarOut(false)}>
                                    <img src={closeDark} alt="closeDark"
                                         className="w-[100%] h-[100%] object-contain"/>
                                </button>

                                <div className="flex justify-center mt-2">
                                    <img src={googleLogo} alt="GoogleReviewsIcon2"
                                         className="w-[50%] h-[50%] object-contain"/>
                                </div>

                                <h2 className="text-2xl">Fortæl os med få ord, hvad vi kan gør bedre😊</h2>

                                <textarea
                                    placeholder="Skriv din besked her..."
                                    className={`mt-1 p-2 w-full border rounded-md`}
                                />

                                <motion.div whileHover={{scale: 1.05}}>
                                    <button type="submit"
                                            className={`block mx-auto bg-blue-600 hover:bg-blue-700 text-white p-2 rounded-md`}
                                            onClick={handleFeedbackSubmit}>
                                        Submit
                                    </button>
                                </motion.div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoogleReviewsButton;