import {
    instagram,
    AnimatedTeacherMale,
    AnimatedTeacherMale2,
    Student1,
    Student2,
    facebook, Student3, Student4, Student5,
} from "./Assets";

export const ContactInfo = [
    {
        id: "TelefonNr",
        Number: "21 24 18 41",
    },
    {
        id: "email",
        email: "Stephanskoreskole@outlook.dk",
    },
];

export const facts = [
    {
        id: "facts-1",
        title: "Undervisning i høj kvalitet",
        tekst: "Hos Stephans Køreskole går jeg ikke på kompromis med kvaliteten af min undervisning." +
            " jeg sikrer, at du får en solid grunduddannelse som bilist" +
            " gennem min velstrukturerede undervisning.",
    },
    {
        id: "facts-2",
        title: "Fleksible køretimer",
        tekst: "Hos Stephans Køreskole forstår jeg, at mange elever har forskellige og til tider travle hverdage." +
            " Derfor tilbyder jeg fleksible køretimer, så du kan tilrettelægge din køreuddannelse" +
            " til din travle hverdag og lære at køre bil i dit eget tempo uden faste kørelektioner.",
    },
    {
        id: "facts-53",
        title: "Stressfri & tryg atmosfære ",
        tekst: " Jeg skaber en tryg og støttende atmosfære, hvor du kan føle dig godt tilpas" +
            " og have det sjovt undervejs i køreuddannelsen. Hos mig er du i gode hænder," +
            " og jeg gør mit bedste for at sikre, at du når dine køre mål med succes.",
    },
];

export const socialMedia = [
    {
        id: "social-media-1",
        icon: facebook,
        link: "https://www.facebook.com/people/Stephans-Køreskole/61561393915469/",
    },
    {
        id: "social-media-2",
        icon: instagram,
        link: "https://www.instagram.com",
    },
];

export const heroPageText = [
    {
        banner: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        title1: "Stephans Køreskole i Holbæk",
        title2: "Stephans Køreskole",
        UnderTitle1: "bliv hurtigt kørende",
        text: "Hvis du søger en pålidelig køreskole i Holbæk, der prioriterer mere end blot at bestå køreprøven," +
            " er jeg det perfekte valg for dig. ",
        CTA1: "Tilmelding",
        CTA2: "Kontakt",
    },
];

export const teacherData = [
    {
        name: "Stephan",
        position: "Ejer af Stephans Køreskole",
        backgroundImage: AnimatedTeacherMale,
        descriptiveText: "Jeg er uddannet kørelærer siden 2018. Ved siden af det har jeg løbende undervist på et AMU-center." +
            " Jeg er derudover uddannet pædagog og har arbejdet med unge mennesker hele mit liv." +
            " Min køreskole er placeret på Holbæk station og er derfor let tilgængelig for alle."
    },
    {
        name: "Skolevognen",
        position: "Din trofaste skolevogn",
        backgroundImage: AnimatedTeacherMale2,
        descriptiveText: "Din trofaste skolevogn, som vil føre dig sikkert igennem trafikken," +
            " samtidig med at du lærer, hvordan man kører bil."
    },
];

export const Lovpakker = [
    {
        name: 'Kørekort til personbil (Kat. B)',
        monthlyPrice: "14.700 kr i alt.",
        yearlyPrice: " 16.700 kr.",
        description: "Indeholder alle de lovpligtige timer som skal tages for at du kan få kørekort til bil.",
        description2: "Yderligere skal der også påregnes udgifter til leje og diverse. Klik her for læse mere.",
        info1: "Manøvrebane 2.500 kr.",
        info2: "Lovpligtig teoriundervisning 2.900 kr. ",
        info3: "16 kørelektioner af 550 kr. (lovpligtigt) 8.800 kr.",
        info4: "Køreteknisk anlæg. 2.500 kr.",
        info5: "Rabat (indtil 1/8) 1.000 kr.",
        info6: "Rabat (start med en ven/veninde) 1.000 kr.",
    },
];

export const recommendationsData = [
    {
        id: 1,
        text: "Kommer snart.",
        name: "Person 1",
        position: "Tidligere elev hos Stephans Køreskole",
    },
    {
        id: 2,
        text: "Kommer snart.",

        name: "Person 2",
        position: "Tidligere elev hos Stephans Køreskole",
    },
    {
        id: 3,
        text: "Kommer snart.",
        name: "Person 3",
        position: "Tidligere elev hos Stephans Køreskole",
    },

];

export const HoldStartHeroPageText = [
    {
        text1Bil: "Næste holdopstart bil Kørekort (Kat. B):",
    },
];

export const holdOversigtBil = [
    {title: 'Hold 1', date: '5/08/2024', time: '15:30', seatsLeft: "Ledige pladser"},
    {title: 'Hold 2', date: '19/08/2024', time: '15:30', seatsLeft: "Ledige pladser"},
];

export const otherDeals = [
    {
        name: 'Generhvervelse - (Kat. B)',
        monthlyPrice: "1.500",
        description: "Denne pakke er til dig, som har en betinget eller ubetinget frakendelse.",
        description2: "Det videre forløb for generhvervelse afhænger af, hvad det er for en type frakendelse" +
            " du har fået af politiet. Ring eller skriv til mig igennem kontakt formularen forneden, så kan vi lægge en plan sammen.",
        info1: "2 Teori lektioner",
        info2: "3 praktiske lektioner",
        info3: "1. gang leje af skolevogn til praktisk prøve",
        info4: "Online teori (3 mdr.)",
    },
];

export const studentCarousel = [
    {
        backgroundImage: Student1,
    },
    {
        backgroundImage: Student2,
    },
    {
        backgroundImage: Student3,
    },
    {
        backgroundImage: Student4,
    },
    {
        backgroundImage: Student5,
    },
    {
        backgroundImage: Student1,
    },
];

export const FAQData = [
    {
        id: 1,
        question: "Hvornår kan jeg begynde at tage kørekort til bil?",
        answer: "Du kan starte din kørekortproces, når du når en alder af 16 år og 9 måneder, hvilket er 3 måneder før din 17-års fødselsdag."
    },
    {
        id: 2,
        question: "Hvor lang tid tager det at få bilkørekort?",
        answer: "Et typisk kørekortskursus strækker sig over ca. 2-3 måneder, dog kan dette variere. For en hurtigere proces anbefales det at få ordnet lægeerklæringen og deltage i det obligatoriske førstehjælpskursus relateret til trafikken så hurtigt som muligt."
    },
    {
        id: 3,
        question: "Hvordan foregår teoriprøven?",
        answer: "Teoriprøven består af 25 billeder, hvor der til hvert billede er givet 2-4 svarmuligheder. For at bestå teoriprøven må du maksimalt have 5 fejl."
    },
    {
        id: 4,
        question: "Hvordan foregår den praktiske køreprøve?",
        answer: "Den praktiske prøve varer cirka 45 minutter. Du bliver bedømt af en prøvesagkyndig, typisk en politibetjent. Han indtager kørelærerens sæde og vurderer dine kørefærdigheder samt sikker kørsel i trafikken. Prøven starter med en gennemgang af bilens tekniske aspekter."
    },
    {
        id: 5,
        question: "Hvad gør jeg, hvis jeg ikke består min teoriprøve?",
        answer: "Hvis du ikke består teoriprøven, skal du kontakte din kørelærer, som vil hjælpe med at bestille en ny teoriprøve til dig. Du skal betale et nyt gebyr for at kunne deltage i den nye teoriprøve."
    },
    {
        id: 6,
        question: "Hvad koster en køreprøve?",
        answer: "En køreprøve (inklusive teoriprøve og praktisk prøve) har en pris på 1170 kr. hos Færdselsstyrelsen. (Dette er eksklusivt leje af bil og opvarmning til den praktiske prøve.)"
    },
    {
        id: 7,
        question: "Hvordan tilmelder jeg mig teoriprøven?",
        answer: "Din kørelærer vil normalt tilmelde dig teoriprøven, når han eller hun mener, at du er klar."
    },
    {
        id: 8,
        question: "Hvorfor kaldes det den lovpligtige pakke/lovpakken?",
        answer: "Betegnelsen 'den lovpligtige pakke' anvendes, da den indeholder alle de nødvendige og lovbestemte timer, der kræves for at opnå kørekort. Den lovpligtige pakke inkluderer teoriundervisning, manøvrebane, køreteknisk anlæg (glatbane) og 16 kørelektioner på vej."
    },
    {
        id: 9,
        question: "Hvornår får jeg resultatet af min teoriprøve?",
        answer: "Du modtager resultatet af din teoriprøve umiddelbart efter prøven."
    },
    {
        id: 10,
        question: "Kan jeg tage ekstra køretimer, hvis jeg har brug for det?",
        answer: "Ja, du kan tage ekstra køretimer, hvis du føler, at du har brug for mere træning. (hvilket de fleste som regel også gør)"
    },
    {
        id: 11,
        question: "Hvornår kan jeg begynde at øvelseskøre?",
        answer: "Du kan begynde at øvelseskøre med din kørelærer ude i trafikken, når du have 4 lektioner på manøvrebane, som er en lukket øvelsesplads."
    },
    {
        id: 12,
        question: "Hvad er en manøvrebane?",
        answer: "En manøvrebane, også kaldet en kravlegård, er det sted, hvor den praktiske undervisning starter. Det er her, du lærer de grundlæggende færdigheder, der kræves for at begynde at køre. Manøvrebaneundervisningen foregår normalt med tre elever ad gangen og strækker sig typisk over 3 timer."
    },
    {
        id: 13,
        question: "Hvilken bil skal jeg bruge til den praktiske prøve?",
        answer: "Du skal selv sørge for leje af bil til den praktiske køreprøve, så i princippet kan du leje hvilken som helst skolebil. De fleste vælger dog at anvende den skolebil, de har trænet i hos køreskolen."
    },
    {
        id: 14,
        question: "Hvad sker der, hvis jeg ikke består den praktiske prøve?",
        answer: "Hvis du ikke består den praktiske prøve, skal du aftale en ny tid med din kørelærer."
    },
    {
        id: 15,
        question: "Hvor mange kørelektioner er inkluderet i lovpakken?",
        answer: "Lovpakken inkluderer 16 kørelektioner på vej."
    },
    {
        id: 16,
        question: "Hvad skal jeg medbringe til teoriprøven?",
        answer: "Til teoriprøven skal du medbringe gyldigt ID samt eventuelle dokumenter som køreskolen har givet dig."
    },
    {
        id: 17,
        question: "Hvor mange fejl må jeg have til teoriprøven?",
        answer: "Du må maksimalt have 5 fejl ud af 25 spørgsmål for at bestå teoriprøven."
    },
    {
        id: 18,
        question: "Hvad er aldersgrænsen for at tage kørekort til bil?",
        answer: "Aldersgrænsen for at tage kørekort til bil er 17 år, men du kan starte undervisningen, når du er 16 år og 9 måneder."
    },
    {
        id: 19,
        question: "Hvor længe kan jeg holde pause i min undervisning?",
        answer: "Du må ikke holde pause i mere end 3 måneder, da dette vil kræve, at du skal starte undervisningen helt forfra."
    },
    {
        id: 20,
        question: "Hvordan foregår køreundervisningen på vejen?",
        answer: "Køreundervisningen på vejen foregår med en kørelærer ved din side, og du vil lære at håndtere forskellige trafiksituationer og køreforhold."
    },
    {
        id: 21,
        question: "Kan jeg tage kørekortet på kortere tid end normalt?",
        answer: "Ja, det er muligt at tage kørekortet på kortere tid, men det kræver, at du planlægger dine lektioner tættere sammen og er meget fokuseret på at bestå prøverne første gang."
    }
];

export const StandardPrislistePersonbil = [
    {
        name: "Administrationsgebyr",
        cost: "700 kr."
    },
    {
        name: "Færdselsrelateret førstehjælp",
        cost: "800 kr."
    },
    {
        name: "Online adgang til teoribog og prøver - 120 dage",
        cost: "550 kr."
    },
    {
        name: "Prøvegebyr til færdselsstyrelsen",
        cost: "1.170 kr."
    },
    {
        name: "Kørelektion - bil (45 min)",
        cost: "600 kr."
    },
    {
        name: "Lægeattest (ca. pris ved egen læge)",
        cost: "450 kr."
    },
    {
        name: "Godkendt foto (ca. pris ved fotograf)",
        cost: "60 kr."
    },
    {
        name: "Forlængelse af onlineadgang - 30 dage",
        cost: "225 kr."
    },
    {
        name: "Administrationsgebyr ved opdelt betaling pr. gang",
        cost: "500 kr."
    },
    {
        name: "Holdbaseret teorikursus",
        cost: "3.500 kr."
    },
    {
        name: "Individuel teorilektion (45 min)",
        cost: "600 kr."
    },
    {
        name: "Komplet manøvrebane (4 lektioner)",
        cost: "3.000 kr."
    },
    {
        name: "Komplet glatbane til bil (4 lektioner)",
        cost: "2.600 kr."
    },
    {
        name: "Køretime",
        cost: "800 kr."
    },
    {
        name: "Opgørelse",
        cost: "400 kr."
    },
    {
        name: "Overtagelse fra anden køreskole",
        cost: "1.000 kr."
    },
    {
        name: "Rekonstruktion af lektionsplan",
        cost: "1.000 kr."
    },
    {
        name: "Rekonstruktion af ansøgning",
        cost: "1.000 kr."
    },
];


export const standardPrislisteMotorcykel = [
    {
        name: "Holdbaseret teorikursus",
        cost: "3.000 kr."
    },
    {
        name: "Individuel teorilektion (45 min)",
        cost: "600 kr."
    },
    {
        name: "Kørelektion - motorcykel",
        cost: "600 kr."
    },
    {
        name: "Leje af motorcykeludstyr indtil bestået køreprøve",
        cost: "500 kr."
    },
    {
        name: "Online adgang til teoribog og prøver - 120 dage",
        cost: "550 kr."
    },
    {
        name: "Forlængelse af onlineadgang - 30 dage",
        cost: "225 kr."
    },
    {
        name: "Komplet manøvrebane (4 lektioner)",
        cost: "2.500 kr."
    },
    {
        name: "Køreteknisk anlæg - motorcykel (5 lektioner)",
        cost: "2.600 kr."
    },
    {
        name: "Praktisk køreprøve - motorcykel. Inkl. baneleje",
        cost: "2.300 kr."
    },
    {
        name: "Færdselsrelateret førstehjælp",
        cost: "800 kr."
    },
    {
        name: "Prøvegebyr ved almindelig køreprøve (motorcykel)",
        cost: "1.170 kr."
    },
    {
        name: "Lægeattest (ca. pris ved egen læge)",
        cost: "450 kr."
    },
    {
        name: "Godkendt foto (ca. pris ved fotograf)",
        cost: "60 kr."
    },
];

export const standardPrislisteExtra = [
    {
        name: "Administrationsgebyr",
        cost: "700 kr."
    },
    {
        name: "Individuel teorilektion (45 min)",
        cost: "600 kr."
    },
    {
        name: "Kørelektion - bil (45 min)",
        cost: "600 kr."
    },
    {
        name: "Opgørelse",
        cost: "400 kr."
    },
    {
        name: "Online adgang til teoribog og prøver - 4 mdr",
        cost: "550 kr."
    },
    {
        name: "Forlængelse af onlineadgang - 1 mdr",
        cost: "225 kr."
    },
    {
        name: "Overtagelse fra anden køreskole",
        cost: "1.000 kr."
    },
    {
        name: "Prøvegebyr til færdselsstyrelsen",
        cost: "1.170 kr."
    },
    {
        name: "Lægeattest (ca. pris ved egen læge)",
        cost: "450 kr."
    },
    {
        name: "Godkendt foto (ca. pris ved fotograf)",
        cost: "60 kr."
    },
];

export const andreUdgifterData = [
    {
        name: "Prøvegebyr til Færdselsstyrelsen",
        cost: "1.170 kr."
    },
    {
        name: "Online teoriadgang",
        cost: "300 kr."
    },
    {
        name: "Leje af bil til køreprøve inkl. opvarmning",
        cost: "1.300 kr."
    },
    {
        name: "Færdselsrelateret førstehjælp (kan variere)",
        cost: "600-800 kr."
    },
    {
        name: "Lægeerklæring (kan variere)",
        cost: "500 kr."
    },
    {
        name: "Pasfoto (kan variere)",
        cost: "100 kr."
    },
];

