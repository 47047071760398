import React from 'react';
import styles from '../style';
import {ContactFormular, Info, Footer, HeroPage, TeacherCarousel, Lovpakken, Recommendations, BackToTopButton, GoogleReviewsButton} from "../Components/ComponentsIndex";
import GoogleMaps from "../Components/GoogleMaps/GoogleMaps";

const HomePage = () => {

    return (
        <div className = "bg-black dark:bg-white w-full overflow-hidden" >

            <div className = {`bg-black dark:bg-white ${styles.flexStart} mt-[50px]`} >
                <div className = {`bg-black dark:bg-white`} >
                    <HeroPage />
                </div >
            </div >

            <div className = {`bg-black dark:bg-white ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-black dark:bg-white ${styles.boxWidth}`} >
                    <Info />
                </div >
            </div >

            <div className = {`bg-black dark:bg-gray-100 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-black dark:bg-gray-100 ${styles.boxWidth}`} >
                    <Lovpakken />
                </div >
            </div >

            <div className = {`bg-black dark:bg-white ${styles.flexStart} `} >
                <div className = {`bg-black dark:bg-white ${styles.boxWidth}`} >
                    <TeacherCarousel />
                </div >
            </div >

            <div className = {`bg-black dark:bg-gray-100 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-black dark:bg-gray-100 ${styles.boxWidth}`} >
                    {/*<Recommendations/>*/}
                    <ContactFormular />
                    <GoogleMaps />
                </div >
            </div >

            <div className = {`bg-zinc-900 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-zinc-900 ${styles.boxWidth}`} >
                    <Footer />
                </div >
            </div >
            <BackToTopButton />
            <GoogleReviewsButton />
        </div >
    );
}

export default HomePage;
