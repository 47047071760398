import { motion } from 'framer-motion';
import React from 'react';
import styles, {customColor} from "../../style";
import RecommendationsCard from "./RecommendationsCard";
import {googleLogo} from "../../Assets";

const Recommendations = () => {
     const handleLinkClick = () => {
            window.open('https://www.google.com/?client=safari', '_blank');
        };;

    return (
        <section id="Projects">
            <div className="grid justify-items-center mb-24 my-10">
                <h2 className={`${styles.flexCenter} text-center font-poppins font-semibold
                    text-[42px] lg:text-[52px] leading-[55px] lg:leading-[75px] ${customColor.titleColor}`}>
                    Dette siger vores elever om os.
                </h2>
                <div className={`${styles.paragraphNoColor} ${styles.flexCenter} text-white dark:text-gray-500 font-semibold text-center lg:mx-40 mb-5`}>
                    Søde anmeldelser fra vores dejlige elever
                </div>
                <div>
                    <RecommendationsCard />
                </div>

                <div className="flex flex-col md:flex-row items-center mt-4">
                    <div className="flex items-center">
                        {[...Array(5)].map((_, index) => (
                            <svg
                                key={index}
                                className="w-4 h-4 text-yellow-500 me-0.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                            >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                            </svg>
                        ))}
                        <p className="ms-2 text-sm font-bold dark:text-gray-900 text-white">4.9/5</p>
                        <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
                    </div>
                    <motion.div whileHover={{ scale: 1.05 }}>
                        <a
                            className="text-sm font-medium dark:text-gray-900 text-white"
                            onClick={handleLinkClick}
                            href="#"
                        >
                            kommer snart - <img src={googleLogo} alt="GoogleLogo" style={{ display: 'inline', width: '60px', height: '30px' }} />
                        </a>
                    </motion.div>
                </div>

            </div>
        </section>
    );
};

export default Recommendations;