import React from 'react';
import styles from '../style';
import {ContactFormular, Footer, GoogleReviewsButton, Recommendations, FAQHeroPage} from "../Components/ComponentsIndex";
import BackToTopButton from "../Components/ExtraButtons/BackToTopButton";
import FAQAccordion from "../Components/InfoComponent/FAQAccordion";

const ContactPage = () => {

    return (
        <div className = "bg-black dark:bg-gray-100 w-full overflow-hidden" >

            <div className = {`bg-black dark:bg-gray-100 ${styles.flexStart} mt-14 sm:mt-[100px]`} >
                <div className = {`bg-black dark:bg-gray-100 ${styles.boxWidth}`} >
                    <FAQHeroPage/>
                    <FAQAccordion />
                    <ContactFormular />
                    <Recommendations/>
                </div >
            </div >

            <div className = {`bg-zinc-900 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-zinc-900 ${styles.boxWidth}`} >
                    <Footer />
                </div >
            </div >
            <BackToTopButton />
            <GoogleReviewsButton />
        </div >
    );
}

export default ContactPage;
