import React, { useState } from "react";

interface AccordionProps {
    title: string;
    answer: string;
    isLast: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ title, answer, isLast }) => {
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

    return (
        <div className={`py-2 ${!isLast ? "border-b border-gray-300" : ""}`}>
            <button
                onClick={() => setAccordionOpen(!accordionOpen)}
                className="flex justify-between items-center w-full py-1 focus:outline-none"
            >
                <span className="text-start text-lg font-medium text-white dark:text-gray-700 px-2">{title}</span>
                <svg
                    className={`transform transition-transform duration-300 ${accordionOpen ? "rotate-180" : ""}`}
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>
            <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    accordionOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                }`}
            >
                <div className="p-2 text-white dark:text-gray-600 text-sm text-start">{answer}</div>
            </div>
        </div>
    );
};

export default Accordion;
