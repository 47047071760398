import React, { useEffect, useState } from 'react';
import { close, closeDark, DJLogo, DJLogoDark, menu, menuDark } from '../../Assets';
import { Link } from "react-router-dom";
import {ScrollToTop, ThemeSwitcher} from "../ComponentsIndex";

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const [isBlurry, setIsBlurry] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsBlurry(true);
            } else {
                setIsBlurry(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <ScrollToTop />
            <nav className={`fixed top-0 z-10 flex h-72 max-h-[75px] w-full dark:bg-white shadow-lg
            ${isBlurry ? 'bg-white backdrop-filter backdrop-blur-lg bg-opacity-5 w-full' : ''} px-[20px]`}>

                {/* The responsive menu large screen */}
                <ul className="list-none sm:flex hidden justify-center items-center flex-1">
                    <div className="xl:mr-96 lg:mr-62 md:mr-12 sm:mr-8">
                        <a href="/" className="block dark:hidden">
                            <img src={DJLogo} alt="DJLogo" className="sm: w-[150px] h-[52px]  mr-56" />
                        </a>
                        <a href="/" className="hidden dark:block">
                            <img src={DJLogoDark} alt="DJLogo" className="sm: w-[150px] h-[52px] mr-56" />
                        </a>
                    </div>

                    <div>
                        <Link to="/" className={`font-poppins font-normal cursor-pointer text-[16px] px-2
                         md:mr-10 sm:mr-0 text-white dark:text-black hover:border-b-2
                          hover:border-white dark:hover:border-black`}>
                            Forside
                        </Link>
                        <Link to="/Holdstart" className={`font-poppins font-normal cursor-pointer text-[16px] px-2
                         md:mr-10 sm:mr-0 text-white dark:text-black hover:border-b-2
                          hover:border-white dark:hover:border-black`}>
                            Holdstart/Tilmeld
                        </Link>
                        <Link to="/Kontakt" className={`font-poppins font-normal cursor-pointer text-[16px] px-2
                         md:mr-10 sm:mr-0 text-white dark:text-black hover:border-b-2
                          hover:border-white dark:hover:border-black`}>
                            Kontakt
                        </Link>
                        <Link to="/FAQ" className={`font-poppins font-normal cursor-pointer text-[16px] px-2
                         lg:mr-10 sm:mr-0 text-white dark:text-black hover:border-b-2
                          hover:border-white dark:hover:border-black`}>
                            FAQ
                        </Link>
                    </div>

                    <ThemeSwitcher />
                </ul>

                {/* The responsive menu phone */}
                <div className="sm:hidden flex flex-1 items-center">
                    <div className="mr-auto">
                        <a href="/" className="block dark:hidden">
                            <img src={DJLogo} alt="DJLogo" className="w-[100px] h-[52px] mr-56" />
                        </a>
                        <a href="/" className="hidden dark:block">
                            <img src={DJLogoDark} alt="DJLogoDark" className="w-[100px] h-[52px] mr-56" />
                        </a>
                    </div>

                    <div className="block dark:hidden">
                        <img
                            src={toggle ? close : menu}
                            alt="menu"
                            className="w-[28px] h-[28px] object-contain mr-2"
                            onClick={() => setToggle((prev) => !prev)}
                        />
                    </div>

                    <div className="hidden dark:block">
                        <img
                            src={toggle ? closeDark : menuDark}
                            alt="menuDark"
                            className="w-[28px] h-[28px] object-contain mr-2"
                            onClick={() => setToggle((prev) => !prev)}
                        />
                    </div>

                    <div
                        className={`${toggle ? 'flex' : 'hidden'} p-6 dark:bg-white bg-black absolute top-0 right-0 w-screen min-h-screen`}>

                        {/* Rest of the menu */}

                        <ul className="flex flex-col items-center flex-1">

                            <div className="block dark:hidden mb-12">
                                <img
                                    src={toggle ? close : menu}
                                    alt="menu"
                                    className="w-[28px] h-[28px] object-contain"
                                    onClick={() => setToggle((prev) => !prev)}
                                />
                            </div>

                            <div className="hidden dark:block mb-6">
                                <img
                                    src={toggle ? closeDark : menuDark}
                                    alt="menu"
                                    className="w-[28px] h-[28px] object-contain"
                                    onClick={() => setToggle((prev) => !prev)}
                                />
                            </div>

                            <li className={`font-normal cursor-pointer text-[32px] text-white dark:text-black
                            px-2 mb-6 hover:border-b-2 hover:border-white dark:hover:border-black`}>
                                <Link to="/" onClick={() => setToggle((prev) => !prev)}>
                                    Forside
                                </Link>
                            </li>
                            <li className={`font-normal cursor-pointer text-[32px] text-white dark:text-black
                            px-2 mb-6 hover:border-b-2 hover:border-white dark:hover:border-black`}>
                                <Link to="/Holdstart" onClick={() => setToggle((prev) => !prev)}>
                                    Holdstart/Tilmeld
                                </Link>
                            </li>
                            <li className={`font-normal cursor-pointer text-[32px] text-white dark:text-black
                            px-2 mb-6 hover:border-b-2 hover:border-white dark:hover:border-black`}>
                                <Link to="/Kontakt" onClick={() => setToggle((prev) => !prev)}>
                                    Kontakt
                                </Link>
                            </li>
                            <li className={`font-normal cursor-pointer text-[32px] text-white dark:text-black
                            px-2 mb-6 hover:border-b-2 hover:border-white dark:hover:border-black`}>
                                <Link to="/FAQ" onClick={() => setToggle((prev) => !prev)}>
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ThemeSwitcher />
                </div>
            </nav>
        </>
    );
};

export default Navbar;