import { motion } from "framer-motion";
import { teacherData } from "../../infoArray";
import styles, { customColor } from "../../style";
import React from "react";
import { useInView } from 'react-intersection-observer';

const TeacherGrid = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.1, // Trigger when 10% of the section is in view
    });

    return (
        <div ref={ref} className="flex items-center justify-center flex-col h-fit my-10 bg-black dark:bg-white">
            <div>
             {/*   /!* Title *!/*/}
             {/*   <h2 className={`${styles.flexCenter} text-center font-poppins font-bold text-[42px] lg:text-[52px]*/}
             {/*leading-[55px] lg:leading-[75px] ${customColor.titleColor}`}>*/}
             {/*       Mød dine kørelærer*/}
             {/*   </h2>*/}

             {/*   /!* Paragraph *!/*/}
             {/*   <p className={`${styles.flexCenter} text-[16px] leading-[24px] sm:text-[18px] sm:leading-[30.8px]*/}
             {/*text-white dark:text-gray-500 font-semibold text-center mx-auto lg:mx-40`}>*/}
             {/*       Hos Stephans Køreskole har vi mange forskellige kørerlærere tilknyttet og her kan du se en kort*/}
             {/*       introduktion til dem. Du vil møde dem både til teoriaftener, banekørsler,*/}
             {/*       førstehjælp samt selvfølgelig ude i bilerne.*/}
             {/*   </p>*/}
            </div>

            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-12 mt-8 min-w-[600px] md:min-w-[100%]">
                {teacherData.map((teacher, index) => (
                    <div key={teacher.name} className={`flex flex-col ${index % 2 === 0 ? 'sm:items-end items-center' : 'sm:items-start items-center'}`}>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className="relative shadow-lg text-white dark:text-black rounded-xl overflow-hidden cursor-pointer"
                            style={{
                                backgroundImage: `url(${teacher.backgroundImage})`,
                                backgroundSize: "cover",
                                height: "400px", // Adjust as needed for smaller screens
                                width: "60%", // Ensure it stretches full width
                            }}
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={inView ? { opacity: 0 } : { opacity: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 + 0.1 }}
                                className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50"
                            />
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                            transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
                            className="text-white dark:text-black mt-5 flex flex-col items-start text-start mx-2 max-w-[60%]"
                        >
                            <h2 className="text-xl">{teacher.name}</h2>
                            <h2 className="text-sm font-semibold text-gray-500">{teacher.position}</h2>
                            <p className="lg:text-[14px]">{teacher.descriptiveText}</p>
                        </motion.div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeacherGrid;
