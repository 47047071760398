import React from 'react';
import { facts } from '../../infoArray';
import styles, { customColor } from "../../style";

const Info = () => (
    <section className={`${styles.flexCenter} flex-col sm:mb-20 md:px-14`}>
        {/*<div>*/}
        {/*    /!* Title *!/*/}
        {/*    <h2 className={`${styles.flexCenter} text-center font-poppins font-bold text-[38px] sm:text-[42px] lg:text-[52px]*/}
        {/*     leading-[50px] sm:leading-[55px] lg:leading-[75px] ${customColor.titleColor}`}>*/}
        {/*        Holbæk's Fedeste Køreskole*/}
        {/*    </h2>*/}

        {/*    /!* Paragraph *!/*/}
        {/*    <p className={`${styles.flexCenter} text-[16px] leading-[24px] sm:text-[18px] sm:leading-[30.8px]*/}
        {/*     text-white dark:text-gray-500 font-semibold text-center mx-auto lg:mx-40`}>*/}
        {/*        Holbæk's førende køreskole, hvor jeg kombinerer innovative undervisningsmetoder med erfarne instruktør,*/}
        {/*        og fokusere på at skabe en stressfri og tryg atmosfære for vores elever.*/}
        {/*    </p>*/}
        {/*</div>*/}

        <section id="" className={`flex flex-col lg:flex-row`}>
            <div className="flex flex-col lg:flex-row lg:justify-between lg:space-x-6 mt-6 lg:mt-6 w-full">
                {/* 3 Columns for Facts */}
                {facts.map((fact, index) => (
                    <div key={fact.id} className="flex-1 flex flex-col items-center text-center mb-6 lg:mb-0">
                        <p className="text-[60px]">{index === 0 ? '🤩' : index === 1 ? '⏳' : '🤗'}</p>
                        <p className={`font-poppins font-bold text-[20px] text-white dark:text-black uppercase`}>
                            {fact.title}
                        </p>
                        <p className={`font-poppins text-[14px] text-white dark:text-gray-500`}>
                            {fact.tekst}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    </section>
);

export default Info;
