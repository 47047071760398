import styles from "../style";
import {BackToTopButton, Deals, Footer, GoogleReviewsButton, HeroPageHoldStart, OtherDeals, SignUpFormular} from "../Components/ComponentsIndex";
import GoogleMaps from "../Components/GoogleMaps/GoogleMaps";

const HoldStart = () => {
    return (
        <div className = "bg-black dark:bg-white w-full overflow-hidden" >

            <div className = {`bg-black dark:bg-white ${styles.flexStart} mt-[50px]`} >
                <div className = {`bg-black dark:bg-white`} >
                    <HeroPageHoldStart />
                </div >
            </div >

            <div className = {`bg-black dark:bg-gray-100 ${styles.flexStart}`} >
                <div className = {`bg-black dark:bg-gray-100 ${styles.boxWidth}`} >
                    <Deals />
                    <OtherDeals />
                    {/*<StudentsCarousel />*/}
                </div >
            </div >

            <div className = {`bg-black dark:bg-gray-100 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-black dark:bg-gray-100 ${styles.boxWidth}`} >
                    <SignUpFormular />
                    <GoogleMaps />
                </div >
            </div >

            <div className = {`bg-zinc-900 ${styles.paddingX} ${styles.flexStart}`} >
                <div className = {`bg-zinc-900 ${styles.boxWidth}`} >
                    <Footer />
                </div >
            </div >
            <BackToTopButton />
            <GoogleReviewsButton />
        </div >
    );
};
export default HoldStart;

//Hero Section✅
//Table overview of prices + NÆSTE HOLDOPSTART:✅
// Vi tilbyder også: Row with 2 tables (Generhvervelse, Kørselsforbud)✅
//Billeder af elever der bestået✅
//ANDRE UDGIFTER
//Kontakt formular✅
//Footer✅

