import React from 'react';

const GoogleMaps = () => {
    const address = "Jernbaneplads 7, 1. sal, 4300 Holbæk, Denmark";
    const googleMapsUrl = `https://maps.google.com/maps?width=100%25&height=300&hl=en&q=${encodeURIComponent(address)}&t=&z=14&ie=UTF8&iwloc=B&output=embed`;

    return (
        <div className="w-full h-72 md:h-96 mb-6">
            <iframe
                title="google-maps"
                className="w-full h-full border-0"
                src={googleMapsUrl}
                allowFullScreen={true}
            ></iframe>
        </div>
    );
};

export default GoogleMaps;
