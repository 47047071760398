import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { customColor } from "../../style";
import { motion } from "framer-motion";
import { heroPageText } from "../../infoArray";
import { carVideo } from "../../Assets";

const HeroPage = () => {

    useEffect(() => {
        const videoElement = document.querySelector('video');

        if (videoElement) {
            videoElement.setAttribute('playsinline', 'true'); // Ensures the video plays inline on iOS Safari
            videoElement.muted = true; // Ensures autoplay works on mobile
        }
    }, []);

    const scrollToContactFormular = () => {
        // Find the element with the id "Kontakt"
        const kontaktElement = document.getElementById("Kontakt");

        // Check if the element is found
        if (kontaktElement) {
            kontaktElement.scrollIntoView({
                behavior: "smooth",
                block: "start", // You can change this to "center" or "end" based on your preference
            });
        }
    };

    return (
        <section id="home" className="relative flex items-center justify-center h-3/6 w-screen mt-6">
            <video
                className="absolute inset-0 w-full h-full object-cover"
                autoPlay
                loop
                muted
                playsInline // Prevents fullscreen on mobile
            >
                <source src={carVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="relative z-1 text-center text-white p-4 flex flex-col items-center justify-center">
                {heroPageText.map((text, index) => (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        key={index}
                        className="w-full flex flex-col items-center"
                    >
                        {/* The Big title */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 + 0.1 }}
                            className="mb-4 mt-40 w-full text-center"
                        >
                            <h1 className="font-semibold text-[42px] lg:text-[52px] leading-[55px] lg:leading-[75px]">
                                {text.title1} <br className="md:block hidden" />{" "}
                                <span className={`${customColor.gradientDarkBlueTitleNoDarkMode}`}>{text.UnderTitle1}</span>{" "}
                            </h1>
                        </motion.div>

                        {/* The small paragraph */}
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
                            className={`text-white text-[18px] leading-[30.8px] max-w-xl font-semibold text-center`}
                        >
                            {text.text}
                        </motion.p>

                        {/* Call To Action */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
                            className="flex flex-col md:flex-row justify-center items-center mt-6 mb-12 w-full text-center"
                        >
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Link
                                    to="/Holdstart"
                                    className={`block mx-auto ${customColor.buttonGradientDarkBlue}
                                     text-white px-5 py-3 rounded-md`}
                                >
                                    {text.CTA1}
                                </Link>
                            </motion.div>
                            <a
                                href="#"
                                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium
                                 text-center text-white border-2 border-gray-300 rounded-lg hover:border-gray-500
                                 mx-2 my-2 md:my-0"
                                onClick={scrollToContactFormular}
                            >
                                {text.CTA2}
                            </a>
                        </motion.div>
                    </motion.div>
                ))}
            </div>
        </section>
    );
};

export default HeroPage;
