import React from "react";
import styles from "../../style";
import { socialMedia } from "../../infoArray";
import { DJLogo } from "../../Assets";

const Footer = () => {

    const handleLinkClick = () => {
        window.open('https://daniealjohnbaskar.dk/', '_blank');
    };

    return (
        <section className={`${styles.flexCenter} pb-6 flex-col`} >
            <div className="w-full flex flex-col lg:flex-row justify-between items-center pt-0" >
                <div className=" mb-2 lg:mb-0">
                    <a href="/" className="">
                        <img src={DJLogo} alt="DJLogo" className="w-[266px] h-[72.14px] object-contain mt-1" />
                    </a>
                </div>

                <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white
                 mb-2 lg:mb-0 lg:mr-36" >
                    Copyright Ⓒ 2024 Stephans Køreskole - All Rights Reserved.
                </p>

                <div className="flex flex-row mb-2 lg:mb-0" >
                    {socialMedia.map((social, index) => (
                        <img
                            key={social.id}
                            src={social.icon}
                            alt={social.id}
                            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                                index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
                            }`}
                            onClick={() => window.open(social.link)}
                        />
                    ))}
                </div>
            </div>

            <a className="text-sm font-medium text-gray-500 mt-0 lg:mt-[-20px]"
               onClick={handleLinkClick} >
                Hosted by - daniealjohnbaskar.dk
            </a>

            <div className="mt-4 text-center text-white">
                <p>Stephans Køreskole</p>
                <p>CVR: 39212706</p>
                <p>Jernbaneplads 7, 1. sal</p>
                <p>4300 Holbæk</p>
                <p>Telefon: 21 24 18 41</p>
                <address>E-mail: Stephanskoreskole@outlook.dk</address>
            </div>
        </section>
    );
}

export default Footer;
