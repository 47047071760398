import React, { useState } from "react";
import { motion } from "framer-motion";
import {darkgreenCheckMark, greenCheckMark} from "../../Assets";
import {holdOversigtBil, HoldStartHeroPageText, Lovpakker} from "../../infoArray";
import styles, {customColor, layout} from "../../style";

const Deals = () => {

    const scrollToContactFormular = () => {
        // Find the element with the id "SignUp"
        const kontaktElement = document.getElementById("SignUp");

        // Check if the element is found
        if (kontaktElement) {
            kontaktElement.scrollIntoView({
                behavior: "smooth",
                block: "start", // You can change this to "center" or "end" based on your preference
            });
        }
    };

    const scrollToAdditionalExpenses = () => {
        // Find the element with the id "Kontakt"
        const kontaktElement = document.getElementById("yderligereUdgifter");

        // Check if the element is found
        if (kontaktElement) {
            kontaktElement.scrollIntoView({
                behavior: "smooth",
                block: "start", // You can change this to "center" or "end" based on your preference
            });
        }
    };

    const [fullPrice, setFullPrice] = useState(false);

    {/* The Big title */}
    return (
        <div className="py-5 md:px-10 p-4 max-w-screen-2xl mx-auto" id="pricing">
            <div className="text-center">
                <h2 className={`${styles.flexCenter} text-center font-poppins font-bold text-[42px] lg:text-[52px]
             leading-[55px] lg:leading-[75px] ${customColor.titleColor}`}>
                    Kørekort pakker
                </h2>
                <p className={`${styles.paragraphNoColor} ${styles.flexCenter} text-white dark:text-gray-500 font-semibold text-center lg:mx-80 mb-5`}>
                    Vælg pakken der passer dine behov.
                </p>
                {/*<div className="">*/}
                {/*    <label htmlFor="toggle" className="inline-flex items-center cursor-pointer">*/}
                {/*        <span className={`${customColor.titleColor} mr-4 md:mr-8 text-xl md:text-2xl font-semibold`}>*/}
                {/*            Månedlig Rate*/}
                {/*        </span>*/}
                {/*        <div className={`w-12 md:w-14 h-6 transition duration-200 ${fullPrice ? 'bg-green-300' : 'bg-gray-300'} ease-in-out rounded-full`}>*/}
                {/*            <div className={`w-6 h-6 transition duration-200 ease-in-out rounded-full ${fullPrice ? 'bg-green-700 ml-8' : 'bg-gray-500'}`}></div>*/}
                {/*        </div>*/}
                {/*        <span className={`${customColor.titleColor} ml-4 md:ml-8 text-xl md:text-2xl font-semibold`}>*/}
                {/*            Fuld pris*/}
                {/*        </span>*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        type="checkbox"*/}
                {/*        id="toggle"*/}
                {/*        className="hidden"*/}
                {/*        checked={fullPrice}*/}
                {/*        onChange={() => setFullPrice(!fullPrice)}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>

            {/*Næste Holdopstart for Bil Kørekort (Kategori B):*/}
            <section id="HoldOpstartBil" className={`flex md:flex-row flex-col ${styles.paddingYHalf}`}>
                {HoldStartHeroPageText.map((text, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className={`flex-1 flex items-start flex-col xl:px-0 lg:px-16 text-start md:text-start`}
                    >
                        {/* The Big title */}
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 + 0.1 }}
                            className="flex flex-col justify-between text-center sm:text-start w-full"
                        >
                            <h1 className="flex-1 font-semibold text-[26px] md:text-[42px] lg:text-[52px] leading-[36px] md:leading-[55px] lg:leading-[65px] text-white dark:text-black">
                                {text.text1Bil}
                            </h1>
                        </motion.div>

                        {/* Table overview on the left*/}
                        <div className="container mx-auto mt-4 overflow-x-auto">
                            <table className="min-w-full bg-black dark:bg-white shadow-md rounded-lg">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-2 md:px-4 border-b text-white dark:text-black">Hold</th>
                                        <th className="py-2 px-2 md:px-4 border-b text-white dark:text-black">Dato</th>
                                        <th className="py-2 px-2 md:px-4 border-b text-white dark:text-black">Tid</th>
                                        <th className="py-2 px-2 md:px-4 border-b text-white dark:text-black">Pladser tilbage</th>
                                        <th className="py-2 px-2 md:px-4 border-b text-white dark:text-black"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {holdOversigtBil.map((row, index) => (
                                        <tr key={index} className="dark:hover:bg-gray-100 text-white dark:text-black">
                                            <td className="py-1 px-2 md:px-4 border-b text-center">{row.title}</td>
                                            <td className="py-1 px-2 md:px-4 border-b text-center">{row.date}</td>
                                            <td className="py-1 px-2 md:px-4 border-b text-center">{row.time}</td>
                                            <td className="py-1 px-2 md:px-4 border-b text-center">{row.seatsLeft}</td>
                                            <td className="py-1 px-2 md:px-4 border-b text-center">
                                                <motion.div whileHover={{ scale: 1.05 }}>
                                                    <button
                                                        className={`block mx-auto ${row.seatsLeft.toLowerCase() === "ingen pladser" ? "bg-red-500" : customColor.buttonGradientDarkBlue} text-white px-3 py-1 rounded-md`}
                                                        disabled={row.seatsLeft.toLowerCase() === "ingen pladser"}
                                                        onClick={scrollToContactFormular} >
                                                        {row.seatsLeft.toLowerCase() === "ingen pladser" ? "Lukket" : "Tilmeld"}
                                                    </button>
                                                </motion.div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </motion.div>
                ))}

                {/*The deal on the right*/}
                <div className={`${layout.sectionInfo} mt-4 sm:mt-0`}>
                    <motion.div
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.2 }}
                        className="grid grid-cols-1 max-w-[600px] mx-auto xl:ml-44"
                    >
                        {Lovpakker.slice(0, 1).map((Pakke, index) => (
                            <div key={index} className={`dark:border py-10 md:px-6 px-4 rounded-lg shadow-3xl
                             transition-transform transform hover:scale-105 hover:shadow-2xl ${customColor.gradientDarkBlueNoDarkMode}`}>
                                <h3 className="text-white dark:text-black text-2xl font-bold text-center">{Pakke.name}</h3>
                                <p className="text-white dark:text-black text-tertiary text-center my-6">{Pakke.description}</p>
                                <p className="text-white dark:text-black mt-5 text-center text-secondary text-4xl font-bold">
                                    {fullPrice ? `${Pakke.yearlyPrice}` : `${Pakke.monthlyPrice}`}
                                </p>
                                <ul className="text-white dark:text-black mt-4 space-y-2 px-4">
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info1}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info2}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info3}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info4}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info5}
                                    </li>
                                    <li className="flex flex-row gap-1 items-center">
                                        <img src={darkgreenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain dark:hidden" />
                                        <img src={greenCheckMark} alt="icon" className="w-[8%] h-[8%] object-contain hidden dark:inline" />
                                        {Pakke.info6}
                                    </li>
                                </ul>
                                <div>
                                    <p className={`${styles.paragraphNoColor} ${styles.flexCenter} 
                                       text-white dark:text-black font-semibold text-sm leading-5 text-center mt-6`}>
                                        Alt betales løbende (teoriundersning og manøvrebane betales inden manøvrebane).
                                    </p>
                                </div>
                                <motion.div whileHover={{ scale: 1.05 }}>
                                    <p onClick={scrollToAdditionalExpenses}
                                       className={`${styles.paragraphNoColor} ${styles.flexCenter} 
                                       text-white dark:text-gray-500 underline text-tertiary text-sm leading-6 text-center mt-6`}>
                                        {Pakke.description2}
                                    </p>
                                </motion.div>

                                {/* button */}
                                <div className="w-full mx-auto flex items-center justify-center mt-5">
                                    <motion.div whileHover={{ scale: 1.05 }}>
                                        <button className="text-white dark:text-black mt-6 px-10 text-secondary border border-secondary
                                font-semibold py-2 rounded-lg hover:shadow-md light:hover:border-gray-500"
                                                onClick={scrollToContactFormular} >
                                            Tilmeld dig her!
                                        </button>
                                    </motion.div>
                                </div>
                            </div>
                        ))}
                    </motion.div>
                </div>
            </section>
        </div>
    );
};

export default Deals;