import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Navbar} from "./Components/ComponentsIndex";
import HomePage from "./Pages/HomePage";
import ContactPage from "./Pages/ContactPage";
import HoldStart from "./Pages/HoldStart";
import FAQPage from "./Pages/FAQPage";


function App() {
    return (
        <div className="App">
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/Holdstart" element={<HoldStart />} />
                    <Route path="/Kontakt" element={<ContactPage />} />
                    <Route path="/FAQ" element={<FAQPage />} />
                    <Route path="*" element={<h1> PAGE NOT FOUND</h1>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;