import React from "react";
import { motion } from "framer-motion";
import { heroPageText } from "../../infoArray";
import {customColor} from "../../style";

const HoldStartHeroPage = () => {

    return (
        <section id="home" className={`flex md:flex-row flex-col py-6 h-fit`}>
            {heroPageText.map((text, index) => (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className={`flex-1 flex justify-center items-center md:items-start flex-col xl:px-0 lg:px-16 sm:px-6 px-6 text-center md:text-start`}
                    key={index}
                >
                    {/* The Big title */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 + 0.1 }}
                        className="flex flex-row justify-between items-center w-full"
                    >
                        <h1 className={`flex-1 font-semibold text-[42px] lg:text-[52px] leading-[55px] lg:leading-[75px]
                         ${customColor.titleColor} text-center`}>
                            Her kan du finde de mest oftest stillet spørgsmål
                        </h1>
                    </motion.div>

                    {/* Call To Action */}
                </motion.div>
            ))}


        </section>
    );
};

export default HoldStartHeroPage;
